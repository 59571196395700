<template>
  <div class="main-content">
    <div class="head-top">
      <div class="tab-list">
        <div
          class="tab"
          v-for="(item, index) in tabList"
          :key="index"
          :class="active == index ? 'active' : ''"
          @click="onTab(index, item.label)"
        >
          {{ item.value }}
        </div>
      </div>
      <!-- <el-button type="primary" class="export">批量导出</el-button> -->
    </div>
    <div class="top">
      <el-form
        :inline="true"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="订单号">
              <el-input
                v-model="form.order_sn"
                placeholder="请输入订单号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名">
              <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="telephone">
              <el-input
                v-model="form.telephone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="课程">
              <el-select v-model="form.ip_type" placeholder="请选择课程">
                <el-option
                  v-for="item in ipTypeCourseList"
                  :key="'ipType'+item.ipType" :label="item.courseName" :value="item.ipType"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="授课方式">
              <el-select v-model="form.teach_type" placehoslder="请选择授课方式">
                <el-option
                  v-for="item in teachTypeList"
                  :key="item.id"
                  :value="item.value"
                  :label="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" v-preventReClick @click="getStudentList">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="order_sn" label="订单号"></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
        <el-table-column prop="courseName" label="课程" width="200" ></el-table-column>
        <el-table-column prop="teachType" label="授课方式" width="120"></el-table-column>
        <el-table-column prop="create_time" label="提交时间" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | stringData }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.status }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="paging">
        <el-pagination
          background
          :current-page="page"
          @current-change="getStudentList"
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="totalPage"
        >
        </el-pagination>
      </div>
      <el-empty description="暂无数据" v-if="!tableData.length"></el-empty>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { deleteField } from "@/utils";
import { idCardValidity,phoneRules,checkEmail } from "@/utils/rule.js"
export default {
  filters: {
    stringData: function (date) {
      return moment(date * 1000).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
      active: this.$route.params.id ? this.$route.params.id : "0",
      tabList: [
        {
          label: "",
          value: "全部订单",
        },
        {
          label: "unpaid",
          value: "未支付",
        },
        {
          label: "paid",
          value: "已完成",
        },
      ],
      form: {
        order_sn: "",
        name: "",
        telephone: "",
        ip_type: "",
        teach_type: "",
        queryType: "",
      }, //查询信息
      rules: {       
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: phoneRules, trigger: ['change','blur'] }
        ],
      },
      ipTypeCourseList: [],
      teachTypeList: [{
          label: "线上",
          value: 1,
        },
        {
          label: "线下",
          value: 2,
        }],
      tableData: [], //学员信息表格
      operation: "",
      status: "",
      page: 1,
      totalPage: 0,
      queryType: "",
    };
  },
  created() {
    this.getStudentList(this.page); //学员信息列表
    this.getCommonList();
  },
  methods: {
    onTab(i, type) {
      this.active = i;
      this.queryType = type;
      this.getStudentList();
    },
    getCommonList(){
      this.$http.get('/api/web/common/getCommonList')
      .then(res => {
        this.ipTypeCourseList = res.data.ipTypeCourseList;															
      })
    },
    //获取学员信息表格
    getStudentList(page) {
      const data = deleteField(
        Object.assign(
          this.form,
          { page: page },
          { queryType: this.queryType }
        )
      );
      this.$http
        .post('/api/web/companyOrder/getOrderList', data)
        .then((res) => {
          this.tableData = res.data.list;
          this.totalPage = res.data.totalPage;
          // console.log(this.tableData);
        });
    },
    handleSelectionChange() {},   
  },
};
</script>

<style lang="less" scoped>
.main-content {
  .head-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin-bottom: 10px;
    padding: 0 40px;
    background: #fff;
    .tab-list {
      display: flex;
      .tab {
        margin-right: 60px;
        line-height: 66px;
        color: #666;
        border-bottom: 4px solid transparent;
        cursor: pointer;
        &.active {
          border-bottom: 2px solid #d71a18;
        }
      }
    }
    .export {
      display: flex;
      align-items: center;
      // margin-left: auto;
      height: 38px;
      padding: 0 20px;
      font-size: 16px;
      background: none;
      color: #d71a18;
      border: 1px solid #d71a18;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  /deep/.top {
    background: #fff;
    margin-bottom: 10px;
    padding: 30px 40px 8px;
    .el-button {
      float: right;
      width: 140px;
    }
    .el-form-item {
      width: 100%;
      margin-right: 0;
      .el-form-item__label {
        text-align: right;
      }
    }
    .el-form-item__content {
      width: calc(100% - 80px);
      .el-select {
        width: 100%;
      }
    }
  }
  /deep/.bottom {
    background: #fff;
    padding: 30px 40px 8px;
    text-align: left;
    min-height: 585px;
    .el-table {
      thead {
        color: #333;
      }
      .cell {
        text-align: center;
      }
      th.el-table__cell > .cell {
        text-align: center;
      }
      .el-button {
        border: none;
        font-size: 14px;
      }
    }
    .paging {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      /deep/.el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      /deep/.el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      /deep/.btn-next,
      /deep/.btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
  }
}
</style>
